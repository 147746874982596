<template>
	<div>
		<div class="bg-white padd20 flex-1 mh100">
			<div class="align-items-center fz14 marb20">
				<div class="cure-pointer center new co-333333" @click="Increaseaddress(1)">新建收货地址</div>
				<div class="align-items-center co-333333 marl14 l14">已创建</div>
				<div class="align-items-center co-010085 marl4 marr4">{{list.length}}</div>
				<div class="align-items-center co-333333 l14">个地址，最多可创建</div>
				<div class="align-items-center co-010085 marl4 marr4">30</div>
				<div class="align-items-center co-333333 l14">地址</div>
			</div>
			
			<div style="overflow:auto;height: 1050px;" v-infinite-scroll="load1">
				<div class="address-item padd20 marb20" v-for="(item, i) in list" :key="i">
					<div class="justify-content-center-between w100">
						<div class="align-items-center">
							<div class="left-name co-999999">收货人：</div>
							<div class="co-333333">{{item.name}}</div>
						</div>
						<div class="default fz12 co-010085 center" v-if="item.is_default == 1">默认地址</div>
					</div>
					<div class=" justify-content-between align-items-end">
						<div class="fz16">
							<div class="align-items-center mart10">
								<div class="left-name co-999999">所在地区：</div>
								<div class="co-333333">{{item.city_text}}</div>
							</div>
							<div class="align-items-center mart10">
								<div class="left-name co-999999">手机号：</div>
								<div class="co-333333">{{item.phone}}</div>
							</div>
							<div class="align-items-center mart10">
								<div class="left-name co-999999">详细地址：</div>
								<div class="co-333333">{{item.address}}</div>
							</div>
						</div>
						<div class="align-items-center">
							<div class="fz16 co-010085 cure-pointer" v-if="item.is_default == 0" @click="setUpDefaults(i,item.id)">设为默认地址</div>
							<div class="fz16 co-010085 marl42 cure-pointer" @click.stop="Increaseaddress(2,item)">编辑</div>
							<div class="fz16 co-010085 marl42 cure-pointer" @click="delItem(i,item.id)">删除</div>
						</div>
					</div>
				</div>
				<loadMore :state="state" loading_icon></loadMore>
			</div>
			
			<!-- 确认取消弹窗 -->
			<SureCancelPopup ref="SureCancelPopup" title="是否确定删除?" @sureBtn="sureBtn"></SureCancelPopup>
			
			<!-- 编辑地址 -->
			<el-dialog custom-class="boderra8" v-model="addressInfo"  top="0" center @close='addclose'>
				<div class="fz20 co-333333 center paddt20 paddb20">{{fulltype == 1 ? '新增收货地址' : '修改收货地址'}}</div>
				<div class="center w100">
					<div class="align-items-center popup-name">
						<span class="fz16 co-FE3000">*</span>
						<span class="fz16 co-333333 marl4">收货人</span>
					</div>
					<div class="input-popup-area display-flex marl15">
						<input class="fz16 co-333333 flex-1 h100 input-popup" maxlength="8" v-model="name" placeholder="请输入收货人" />
					</div>
				</div>
				<div class="w100 mart20 display-flex justify-content-center">
					<div class="popup-name mart4">
						<span class="fz16 co-FE3000">*</span>
						<span class="fz16 co-333333 marl4">所在地区</span>
					</div>
					<div class="marl15">
						<div class="input-popup-area  fz16 co-999999 justify-content-center-between cure-pointer" @click="addresShow">
							<span :class="region.length == 0 ? 'co-999999' : 'co-333333'">{{region.length == 0 ? '请选择省/市/区' : `${region[0] || ''}${region[1] || ''}${region[2] || ''}`}}</span>
							<img class="address_pull display-block" :class="showAddress ? 'rotate' : ''" src="@/assets/img/address_pull.png" alt="" />
						</div>
						<div class="address-area-box mart2" :style="`height: ${showAddress ? '238px' : '0px'}`">
							<div class="address-area h100">
								<div class="justify-content-center-between">
									<div class="flex-1 h34 cure-pointer center" :class="addressShowType == 1 ? 'bg-white' : 'bg-E6E6E6'" @click="addressTypeChoose(1)">省份</div>
									<div class="flex-1 h34 cure-pointer center" :class="addressShowType == 2 ? 'bg-white' : 'bg-E6E6E6'" @click="addressTypeChoose(2)">城市</div>
									<div class="flex-1 h34 cure-pointer center" :class="addressShowType == 3 ? 'bg-white' : 'bg-E6E6E6'" @click="addressTypeChoose(3)">县区</div>
								</div>
								<div class="flex-1">
									<el-scrollbar height="204px">
										<div class="align-items-center h34 fz14 co-333333 align-items-center paddlr16 city-item" @click="chooseAddress(item,index)" v-for="(item, index) in addresslist" :key="index">{{item.name}}</div>
									</el-scrollbar>	
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="center w100 mart20">
					<div class="align-items-center popup-name">
						<span class="fz16 co-FE3000">*</span>
						<span class="fz16 co-333333 marl4">手机号</span>
					</div>
					<div class="input-popup-area display-flex marl15">
						<input type="number" class="fz16 co-333333 flex-1 h100 input-popup" maxlength="11" v-model="phone" placeholder="请输入手机号" />
					</div>
				</div>
				<div class="center w100 mart20">
					<div class="display-flex">
						<div class="align-items-center popup-name h100">
							<span class="fz16 co-FE3000">*</span>
							<span class="fz16 co-333333 marl4">详细地址</span>
						</div>
						<div class="marl15">
							<textarea class="fz16 co-333333 flex-1 textarea-popup" v-model="addressDesc" maxlength="50" placeholder="请输入详细地址"></textarea>
							<div class="mart13 align-items-center cure-pointer" @click="defaultChoose">
								<img class="is_choose display-block" :src="require(`@/assets/img/${setupDefault == 1 ? 'is_choose' : 'no_choose'}.png`)" alt="" />
								<span class="fz14 co-333333 display-block marl10">设为默认地址</span>
							</div>
							<div class="mart24 align-items-center paddb20">
								<div class="btn-popup-01 center cure-pointer fz14 co-010085" @click="save">保存</div>
								<div class="btn-popup-02 center cure-pointer fz14 co-333333 marl20" @click="cancel">取消</div>
							</div>
						</div>
					</div>
				</div>
			</el-dialog>
			<div v-loading.fullscreen.lock="fullscreenLoading"></div>
		</div>
		<!-- <div class="center paddt54 paddb15">
			<el-pagination background  class="center "  @current-change="handleCurrentChange"
			    layout="prev, pager, next, jumper" :current-page="page"   :total="maxnum" :page-size="size">
			</el-pagination>
		</div> -->
	</div>
	
</template>

<script>
	export default {
		name: 'MyAddress',
		data() {
			return {
				fullscreenLoading: false,	
				delIndex: -1, // 删除选中下标
				addressInfo: false, // 填写地址弹窗是否显示
				showAddress: false, // 是否显示地址列表
				addressShowType: 1, // 1省 2市 3区
				addresslist: [], // 省市区列表
				name: '', // 收货人姓名
				region: [], // 收货省市区
				phone: '', // 手机号
				addressDesc: '', // 详细地址
				setupDefault: 0, // 设置是否默认
				fulltype: 1, // 1填写地址 2修改地址
				state: 1, // 列表加载状态
				page: 0, // 分页
				loading: false, // 接口加载状态
				list: [], // 列表
				areaId: {
					province_id: '',
					city_id: '',
					district_id: '',
				},
				provinceData: [],
				cityData: [],
				areaData: [],
				addressId:'',//修改的ID
				size: 10,
				maxnum:'' ,//总条数
			}
		},
		mounted() {
			this.getData()
			this.$req({
				method: 'get',
				data: {
					type:1
				},
				url: '/api/maple/region',
				success: res => {
					let data = res;
					this.addresslist = data;
					this.provinceData = data;
					// this.provinceData.map((item)=>{
					// 	item.children.map((it)=>{
					// 		this.cityData =it;
							
					// 	})
					// })
				},
			});
		},
		methods: {
			addclose() {
				this.showAddress = false;
				this.addresslist = []
				this.addressShowType = 1;
				this.region = [];
				this.areaId = {
					province_id: '',
					city_id: '',
					district_id: '',
				}
				this.$req({
					method: 'get',
					data: {
						type: 1
					},
					url: '/api/maple/region',
					success: res => {
						let data = res;
						this.addresslist = data;
						this.provinceData = data;
					},
				});
			
			},
			Increaseaddress(type, item) {
				this.fulltype = type;
				this.addressInfo = true;
				if (this.fulltype == 1) {
					this.name = '';
					this.phone = '';
					this.areaId.province_id = '';
					this.areaId.city_id = '';
					this.areaId.district_id = '';
					this.region = [];
					this.addressDesc = '';
					this.setupDefault = 0;
					this.addressId = '';
				} else {
					this.edit(item);
				}
			},
			load1() {
				if (this.state > 1 || this.loading) return false;
				
				this.getData()
			},
			getData() {
				if (this.loading || this.state > 1) return;
				let page = parseInt(this.page) + 1;
				this.loading = true;
				this.state = 1;
				let size = 20;
				this.$req({
					method: 'get',
					data: {
						page: page,
						size: size,
					},
					url: '/api/maple/address',
					success: res => {
						let list = res;
						if (page == 1) {
							this.list = list;
						} else {
							this.list = this.list.concat(list);
						}
						this.page = page;
						if (list.length == size) {
							this.state = 1;
						} else if (list.length > 0) {
							this.state = 2;
						} else if (this.list.length == 0) {
							this.state = 3;
						}
						this.loading = false;
					},
					fail: error => {
						if (this.lists.length == 0) {
							this.state = 3;
						} else {
							this.state = 2;
						}
						this.loading = false;
					}
				});
				//总条数
				this.$req({
					method: 'get',
					data: {
						show_page:1,
						
					},
					url: '/api/maple/address',
					success: res => {
						console.log(res,'总条数')
						this.maxnum =res;
					},
					fail: error => {
						
					}
				});
			},
			// 设置默认
			setUpDefaults(e,id) {			
				this.$req({
					method: 'put',
					data: {
						id: id,		
					},
					url: '/api/maple/addressDefault',
					success: res => {
						this.$message.success('设置成功');
						this.list.forEach(item => {
							if (item.is_default == 1) {
								item.is_default = 0;
							}
						})
						this.list[e].is_default = 1;
						
					},
				});
			},
			// 编辑
			edit(item) {
				this.name = item.name
				this.phone = item.phone
				this.region[0] = item.city_text;		
				this.addressId = item.id
				this.addressDesc = item.address
				this.areaId = {
					province_id: item.province_id,
					city_id: item.city_id,
					district_id: item.district_id,
				}
				this.setupDefault = item.is_default
			},
			//打开地址列表
			addresShow() {
				this.showAddress = !this.showAddress;
			},
			// 删除
			delItem(e,id) {
				this.delIndex = e;
				this.$refs.SureCancelPopup.open();
			
			},
			// 省市区选择
			addressTypeChoose(e) {
	
				this.addressShowType = e;	
				
			},
			// 地址选择
			chooseAddress(item) {
				this.addresslist = []
				if (this.addressShowType == 1) {
					this.region[0] = item.name;
					this.areaId.province_id = item.id
					this.addresslist = item.children
				}
				if (this.addressShowType == 2) {
					this.region[1] = item.name;
					this.areaId.city_id = item.id
					this.addresslist = item.children
				}
				if (this.addressShowType == 3) {
					this.region[2] = item.name;
					this.areaId.district_id = item.id
					this.showAddress = !this.showAddress;
					
				}
				if(this.showAddress){
					this.addressShowType++;
				}else{
					this.addressShowType = 1;
					this.addresslist = this.provinceData;
				}
				
			},
			// 是否默认
			defaultChoose() {
				if (this.setupDefault == 1) {
					this.setupDefault = 0
				} else {
					this.setupDefault = 1
				}
			},
			// 按钮确认操作
			sureBtn() {
				this.$req({
					method: 'delete',
					data: {
						ids: [this.list[this.delIndex].id],		
					},
					url: '/api/maple/address',
					success: res => {
						this.list.splice(this.delIndex, 1);
						this.$message.success('删除成功');
						this.$refs.SureCancelPopup.close();
		
					},
				});
			},
			// 保存
			save() {
				if (!this.name) {
					this.$message.error('请输入姓名');
					return false
				}
				if (!this.region) {
					this.$message.error('请选择省市区');
					return false
				}

				let phone = /^1[3456789]\d{9}$/;
				if (!phone.test(this.phone, "phone")) {
					this.$message.error("请输入正确的手机号")
					return false;
				}
				if (!this.addressDesc) {
					this.$message.error('请输入详细地址');
					return false
				}
				let method = this.fulltype ==1?'post':'put',obj = {}	
				if(this.addressId){
					method = 'PUT'
					obj = {
						id:	this.addressId
					}
				}
				this.fullscreenLoading = true;
				this.$req({
					method:method,
					data: {
							name: this.name,
							phone: this.phone,
							...this.areaId,
						address: this.addressDesc,
						is_default: this.setupDefault ? 1 : 0,
						...obj,
					},
					url: '/api/maple/address',
					success: res => {		
						this.$message.success(this.fulltype==1?'添加成功':'保存成功')
						this.loading = false;
						this.state = 0;
						this.page = 0;
						this.list=[];
						this.getData();
						this.cancel()
						this.fullscreenLoading = false;
					},
					fail: error => {
					this.fullscreenLoading = false;
					console.log(error,'地址错误')
					}
				});
			},
			// 取消
			cancel() {
				this.addressInfo = false;
			},
			handleCurrentChange(val) {
			   this.page = val;
			   this.list = [];
			   this.loading = false;
			   this.getData()
			    document.documentElement.scrollTop = document.body.scrollTop = 400;
			},
		}
	}
</script>

<style lang="less" scoped>
	.new {
		width: 114px;
		height: 30px;
		background: #FBFFE6;
		border: 1px solid #DCFF03;
		border-radius: 2px;
	}
	
	.address-item {
		border: 1px solid #E6E6E6;
		
		.left-name {
			width: 100px;
			text-align: justify;
			text-align-last: justify;
		}
	}
	
	.default {
		width: 64px;
		height: 24px;
		background: #DCFF03;
		border-radius: 2px;
	}
	
	.input-popup-area {
		padding: 0 16px;
		width: 330px;
		height: 32px;
		background: #FFFFFF;
		border: 1px solid #CCCCCC;
	}
	
	.input-popup {
		outline: none;
		border: 0;
		padding: 0;
		margin: 0;
	}
	
	.popup-name {
		width: 80px;
	}
	
	.address_pull {
		width: 10px;
		height: 10px;
		transition: all 0.3s;
	}
	
	.address-area-box {
		overflow: hidden;
		transition: all 0.3s;
		
		.address-area {
			border: 1px solid #E6E6E6;
			
			.city-item:hover {
				cursor: pointer;
				background: rgba(220, 255, 3, 0.2);
			}
		}
	}
	
	.textarea-popup {
		padding: 12px;
		width: 338px;
		height: 59px;
		background: #FFFFFF;
		border: 1px solid #CCCCCC;
		resize: none;
		outline: none;
	}
	
	.is_choose {
		width: 18px;
		height: 18px;
	}
	
	.btn-popup-01 {
		width: 72px;
		height: 28px;
		background: #DCFF03;
		border-radius: 3px;
	}
	
	.btn-popup-02 {
		width: 70px;
		height: 26px;
		border: 1px solid #999999;
		border-radius: 3px;
	}
	
	.rotate {
		transform: rotate(180deg);
	}
	
	input::-webkit-input-placeholder {
		color: #999999;
		font-size: 16px;
	}
	
	input::-webkit-inner-spin-button{ 
		-webkit-appearance: none !important;
		margin: 0;
		-moz-appearance: textfield;
	}
	
	textarea::-webkit-input-placeholder {
		color: #999999;
		font-size: 16px;
	}
</style>
